// API urls
export const API_URL_ACCOUNT_LOGIN = '/api/account/login'
export const API_URL_ACCOUNT_DETAIL = '/api/account/detail'
export const API_URL_ACCOUNT_ROLES = '/api/account/roles'
export const API_URL_ACCOUNT_REFRESH_TOKEN = '/api/account/refresh-token'
export const API_URL_ACCOUNT_CHANGE_PASSWORD = '/api/account/change-password'
export const API_URL_DASHBOARD = '/api/dashboard'
export const API_URL_PRODUCTS = '/api/products'
export const API_URL_PRODUCTS_STOCKS = '/api/products/stocks'
export const API_URL_PRODUCTS_TRAYS = '/api/products/trays'
export const API_URL_PRODUCTS_REFERENCES_CATEGORIES = '/api/products/references/product-categories'
export const API_URL_PRODUCTS_REFERENCES_MATERIALS = '/api/products/references/product-materials'
export const API_URL_PRODUCTS_REFERENCES_UNITS = '/api/products/references/product-units'
export const API_URL_EMPLOYEES = '/api/employees'
export const API_URL_INVOICES = '/api/invoices'
export const API_URL_INVOICES_PAYMENTS = '/api/invoices/payments'
export const API_URL_INVOICES_PAYMENTS_CANCEL = '/api/invoices/payments/cancel'
export const API_URL_INVOICES_STATUSES = '/api/invoices/statuses'
export const API_URL_INVOICES_CANCEL = '/api/invoices/cancel'
export const API_URL_INVOICES_RECALCULATE = '/api/invoices/recalculate'
export const API_URL_TRAYS = '/api/trays'
export const API_URL_PURCHASES = '/api/purchases'
export const API_URL_PURCHASES_PAYMENTS = '/api/purchases/payments'
export const API_URL_PURCHASES_PAYMENTS_CANCEL = '/api/purchases/payments/cancel'
export const API_URL_PURCHASES_STATUSES = '/api/purchases/statuses'
export const API_URL_PURCHASES_CANCEL = '/api/purchases/cancel'
export const API_URL_GOLD_PRICES = '/api/gold-prices'
export const API_URL_GOLD_PRICES_LATEST = '/api/gold-prices/latest'
export const API_URL_CUSTOMERS = '/api/customers'
export const API_URL_SUPPLIERS = '/api/suppliers'
export const API_URL_REFERENCES_TAX_ITEMS = '/api/references/tax-items'
export const API_URL_REFERENCES_PAYMENT_METHODS = '/api/references/payment-methods'
export const API_URL_DOMAIN_LIST = '/api/domain/list'
export const API_URL_DOMAIN_SETTINGS = '/api/domain/settings'
export const API_URL_REPORTS_SALES = '/api/reports/sales'
export const API_URL_REPORTS_SALES_ALT = '/api/reports/sales/alt'
export const API_URL_REPORTS_SALES_ALT_LIST = '/api/reports/sales/alt/list'
export const API_URL_REPORTS_SALES_ALT_LIST_OUTSTANDING = '/api/reports/sales/alt/list/outstanding'
export const API_URL_REPORTS_PURCHASES = '/api/reports/purchases'
export const API_URL_REPORTS_PRODUCTS_STOCK_CATS_SUM = '/api/reports/products/stock-categories-summary'
export const API_URL_REPORTS_PRODUCTS_STOCK_CAT_MUTATIONS = '/api/reports/products/stock-category-mutations'
export const API_URL_PRODUCT_TRANSFERS = '/api/transfers/products'
export const API_URL_PRODUCT_TRANSFERS_RECEIVE = '/api/transfers/products/receive'
export const API_URL_PRODUCT_TRANSFERS_DIRECTIONS = '/api/transfers/products/directions'
export const API_URL_PRODUCT_TRANSFERS_STATUSES = '/api/transfers/products/statuses'

// Web urls
export const WEB_URL_LOGIN = '/login'
export const WEB_URL_DASHBOARD_HOME = '/dashboard'
export const WEB_URL_ACCOUNT = '/account'
export const WEB_URL_PRODUCTS = '/products'
export const WEB_URL_PRODUCTS_NEW = '/products/new'
export const WEB_URL_PRODUCTS_EDIT = '/products/edit'
export const WEB_URL_PRODUCTS_VIEW = '/products/view'
export const WEB_URL_PRODUCTS_PRINT_BARCODE = '/products/print-barcode'
export const WEB_URL_EMPLOYEES = '/employees'
export const WEB_URL_EMPLOYEES_NEW = '/employees/new'
export const WEB_URL_EMPLOYEES_EDIT = '/employees/edit'
export const WEB_URL_EMPLOYEES_VIEW = '/employees/view'
export const WEB_URL_CUSTOMERS = '/customers'
export const WEB_URL_CUSTOMERS_NEW = '/customers/new'
export const WEB_URL_CUSTOMERS_EDIT = '/customers/edit'
export const WEB_URL_CUSTOMERS_VIEW = '/customers/view'
export const WEB_URL_SUPPLIERS = '/suppliers'
export const WEB_URL_SUPPLIERS_NEW = '/suppliers/new'
export const WEB_URL_SUPPLIERS_EDIT = '/suppliers/edit'
export const WEB_URL_SUPPLIERS_VIEW = '/suppliers/view'
export const WEB_URL_INVOICES = '/invoices'
export const WEB_URL_INVOICES_NEW = '/invoices/new'
export const WEB_URL_INVOICES_VIEW = '/invoices/view'
export const WEB_URL_INVOICES_PRINT = '/invoices/print'
export const WEB_URL_TRAYS = '/trays'
export const WEB_URL_TRAYS_NEW = '/trays/new'
export const WEB_URL_TRAYS_EDIT = '/trays/edit'
export const WEB_URL_TRAYS_VIEW = '/trays/view'
export const WEB_URL_PURCHASES = '/purchases'
export const WEB_URL_PURCHASES_NEW = '/purchases/new'
export const WEB_URL_PURCHASES_VIEW = '/purchases/view'
export const WEB_URL_PURCHASES_PRINT = '/purchases/print'
export const WEB_URL_REFERENCES = '/references'
export const WEB_URL_REFERENCES_GOLD_PRICES = '/references/gold-prices'
export const WEB_URL_REFERENCES_GOLD_PRICES_NEW = '/references/gold-prices/new'
export const WEB_URL_REPORTS = '/reports'
export const WEB_URL_REPORT_SALES = '/reports/sales'
export const WEB_URL_REPORT_SALES_ALT = '/reports/sales/alt'
export const WEB_URL_REPORT_SALES_ALT_LIST = '/reports/sales/alt/list'
export const WEB_URL_REPORT_PURCHASES = '/reports/purchases'
export const WEB_URL_REPORT_PRODUCTS_STOCK_CATEGORIES = '/reports/products/stock-categories'
export const WEB_URL_REPORT_PRODUCTS_STOCK_CATEGORY_MUTATIONS = '/reports/products/stock-category-mutations'
export const WEB_URL_PRODUCT_TRANSFERS = '/product-transfers'
export const WEB_URL_PRODUCT_TRANSFERS_NEW = '/product-transfers/new'
export const WEB_URL_PRODUCT_TRANSFERS_VIEW = '/product-transfers/view'
